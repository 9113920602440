/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-02-28",
    versionChannel: "nightly",
    buildDate: "2024-02-28T00:05:56.108Z",
    commitHash: "05fc77e8cd81de547d25bb657d12adfb2659091d",
};
